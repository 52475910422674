import explorer from "../../img/explorer.png";
export const ListFeatures = [
    {
        name:  "features section.Shiny Scanner.name",
        text:  "features section.Shiny Scanner.text",
        video: "//youtube.com/watch?v=8WSiQ1jAChY",
        donor: true,
    },
    {
        name:  "features section.Virtual GO Plus.name",
        text:  "features section.Virtual GO Plus.text",
        video: "//youtube.com/watch?v=Yk949js9I6o",
        donor: true,
    },
    {
        name:  "features section.Account Manager.name",
        text:  "features section.Account Manager.text",
        donor: false,
    },
    {
        name:  "features section.Team Rocket Blastoff 2.name",
        text:  "features section.Team Rocket Blastoff 2.text",
        video: "//youtube.com/watch?v=Hqrli4a8YEU",
        donor: true,
    },
    {
        name:  "features section.Cooldown Tracker.name",
        text:  "features section.Cooldown Tracker.text",
        video: "//youtube.com/watch?v=sc6B4UDgBHg",
        donor: true,
    },
    {
        name:  "features section.Eggspresso.name",
        text:  "features section.Eggspresso.text",
        donor: true,
    },
    {
        name:  "features section.Tap to Teleport.name",
        text:  "features section.Tap to Teleport.text",
        video: "//youtube.com/watch?v=5IrqRqcJjMg",
        donor: true,
    },
    {
        name:  "features section.Transfer on Catch.name",
        text:  "features section.Transfer on Catch.text",
        donor: true,
    },
    {
        name:  "features section.Tamagotchi.name",
        text:  "features section.Tamagotchi.text",
        video: "//youtu.be/kNrBhBbc1ZY",
        donor: true,
    },
    {
        name:  "features section.Mass Gift.name",
        text:  "features section.Mass Gift.text",
        video: "//youtube.com/watch?v=gNJgbM_Iqxw",
        donor: true,
    },
    {
        name:  "features section.Swift Gift.name",
        text:  "features section.Swift Gift.text",
        donor: false,
    },
    {
        name:  "features section.PVP Metrics.name",
        text:  "features section.PVP Metrics.text",
        donor: true,
    },
    {
        name:  "features section.Quest Tweaks.name",
        text:  "features section.Quest Tweaks.text",
        video: "//youtube.com/shorts/UzJbS97giRI",
        donor: true,
    },
    {
        name:  "features section.Raid Pack.name",
        text:  "features section.Raid Pack.text",
        video: "//youtube.com/watch?v=y0Iwr3pOse0",
        donor: true,
    },
    {
        name:  "features section.Perfect Throw.name",
        text:  "features section.Perfect Throw.text",
        donor: false,
    },
    {
        name:  "features section.Inventory Tweaks.name",
        text:  "features section.Inventory Tweaks.text",
        video: "//youtube.com/shorts/nbPp9bMrqyo",
        donor: false,
    },
    {
        name:  "features section.Incognito Mode.name",
        text:  "features section.Incognito Mode.text",
        donor: true,
    },
    {
        name:  "features section.Magic Bag.name",
        text:  "features section.Magic Bag.text",
        video: "//youtube.com/watch?v=bsez3maWu-g",
        donor: true,
    },
    {
        name:  "features section.Headshot.name",
        text:  "features section.Headshot.text",
        donor: true,
    },
    {
        name:  "features section.Performance Mode.name",
        text:  "features section.Performance Mode.text",
        donor: true,
    },
    {
        name:  "features section.Team Leader Zero Hit KO.name",
        text:  "features section.Team Leader Zero Hit KO.text",
        donor: true,
    },
    {
        name:  "features section.Spawn Booster.name",
        text:  "features section.Spawn Booster.text",
        donor: false,
    },
    {
        name:  "features section.Speed Mode.name",
        text:  "features section.Speed Mode.text",
        donor: false,
    },
    {
        name:  "features section.Pinap Master.name",
        text:  "features section.Pinap Master.text",
        donor: true,
    },
    {
        name:  "features section.Instant Catch.name",
        text:  "features section.Instant Catch.text",
        donor: false,
    },
    {
        name:  "features section.Instant Loading.name",
        text:  "features section.Instant Loading.text",
        video: "//youtube.com/watch?v=u_nDT9k_ZkA",
        donor: true,
    },
    {
        name:  "features section.Instant Spin.name",
        text:  "features section.Instant Spin.text",
        donor: false,
    },
    {
        name:  "features section.Block Non-Shiny Encounters.name",
        text:  "features section.Block Non-Shiny Encounters.text",
        donor: false,
    },
    {
        name:  "features section.Boss Movesets.name",
        text:  "features section.Boss Movesets.text",
        donor: false,
    },
    {
        name:  "features section.Replace Names with IVs in Encounters.name",
        text:  "features section.Replace Names with IVs in Encounters.text",
        donor: false,
    },
    {
        name:  "features section.Show Encounter IV.name",
        text:  "features section.Show Encounter IV.text",
        donor: false,
    },
    {
        name:  "features section.Shows IVs in Inventory.name",
        text:  "features section.Shows IVs in Inventory.text",
        donor: false,
    },
    {
        name:  "features section.Skip Encounter Intro.name",
        text:  "features section.Skip Encounter Intro.text",
        donor: false,
    },
    {
        name:  "features section.Integrated Mock Locations Patch.name",
        text:  "features section.Integrated Mock Locations Patch.text",
        donor: false,
    },

];
// Add the url that points to the discord attachment of apks
export const PatreonLink = "https://patreon.com/pokemod";
export const EspressoAPk = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
export const HalApk = "https://storage.pokemod.dev/Pokemod_Public_v8_1_1r811-lQTgyM76qBc1.apk";
export const AtlasApk = "https://storage.pokemod.dev/Pokemod_Atlas_Public_v24040401_rev2-0Cemk2wrQquo.apk";
export const AegisApk = "https://storage.pokemod.dev/Pokemod_Aegis_Public_v24042801-yTdzFXceIS6O.apk";
export const Pogo32Apk = "https://storage.pokemod.dev/Po_GO_0_319_0_armeabi-gIwR2CiXi6bG.apk";
export const Pogo64Apk = "https://storage.pokemod.dev/Po_GO_0_319_0_arm64-Z1iFOmgDXiMd.apk";
export const ModalHeroSection = [
    {
        title:               "modal.Espresso.title",
        text:                "modal.Espresso.text",
        secondtext:          "modal.Espresso.secondtext",
        thirdtext:           "modal.Espresso.thirdtext",
        link:                EspressoAPk,
        linktext:            "modal.Espresso.linktext",
        secondlink:          "https://patreon.com/pokemod",
        secondlinktext:      "modal.Espresso.secondlinktext",
        event_btn_primary:   "Download Espresso",
        event_btn_secondary: "Unlock All Features",

    }, {
        title:               "modal.EspressoNA.title",
        text:                "modal.EspressoNA.text",
        secondtext:          "modal.EspressoNA.secondtext",
        thirdtext:           "modal.EspressoNA.thirdtext",
        link:                "https://discord.gg/Pokemod",
        linktext:            "modal.EspressoNA.linktext",
        secondlink:          "https://patreon.com/pokemod",
        secondlinktext:      "modal.EspressoNA.secondlinktext",
        event_btn_primary:   "Join us on Discord",
        event_btn_secondary: "Get HAL",
    },
    {
        title:               "modal.HAL.title",
        text:                "modal.HAL.text",
        secondtext:          "modal.HAL.secondtext",
        thirdtext:           "modal.HAL.thirdtext",
        link:                HalApk,
        linktext:            "modal.HAL.linktext",
        secondlink:          "https://patreon.com/pokemod",
        secondlinktext:      "modal.HAL.secondlinktext",
        event_btn_primary:   "Download HAL",
        event_btn_secondary: "Get HAL subscription",
    },
    {
        title:               "modal.Atlas.title",
        text:                "modal.Atlas.text",
        secondtext:          "modal.Atlas.secondtext",
        thirdtext:           "modal.Atlas.thirdtext",
        link:                "/atlas",
        linktext:            "modal.Atlas.linktext",
        secondlink:          "https://mapping.pokemod.dev",
        secondlinktext:      "modal.Atlas.secondlinktext",
        event_btn_primary:   "Learn More",
        event_btn_secondary: "Atlas web-ui",
        event_btn_tertiary:  "Download atlas apk",
        thirdlink:           AtlasApk,
        thirdlinktext:       "modal.Atlas.thirdlinktext",
    },
];

export const MemberCard = [
    {
        title:    "Pro",
        icon:     explorer,
        about:    "membercard.about",
        benefits: {
            title: "membercard.benefits.title",
            items: [
                "membercard.benefits.items.line1",
                "membercard.benefits.items.line2",
                "membercard.benefits.items.line3",
                "membercard.benefits.items.line4",
            ],
        },
        price: 7,
        link:  "https://patreon.com/pokemod",
    },
];
