import { useEffect } from "react";
import { useHistory } from "react-router";

const BackToHome = () => {
    const history = useHistory();
    useEffect(() => {
        history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default BackToHome;
