import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Spinner from "./components/Utils/Spinner";
import { BrowserRouter } from "react-router-dom";
import { init_i18n } from "./i18n";

const rootElement = document.getElementById("root");


init_i18n().then(() => {
    ReactDOM.render(
        <BrowserRouter>
            {/* <Suspense fallback={<Spinner />}> */}
            <App />
            {/* </Suspense> */}
        </BrowserRouter>,
        rootElement,
    );
});
