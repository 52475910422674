import { useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname } }) => {
    const smoothScroll = useCallback(() => {
        const body = document.querySelector("#root");
        if (body) {
            window.requestAnimationFrame(() => {
                body.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                }, { passive: true });
            });
        }
    }, []);

    useEffect(() => {
        smoothScroll();
    }, [pathname, smoothScroll]);

    return children || null;
};

export default withRouter(ScrollToTop);
