import React, { Fragment, lazy, Suspense, useEffect } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import { HalApk, Pogo64Apk, Pogo32Apk } from "./components/FakeDatabase/Fakedatabase";
import BackToHome from "./components/Utils/RedirectToHomepage";
import ScrollToTop from "./components/Utils/ScrollToTop";
import { baseRouteUrl, SUPPORTED_LANGUAGES, removeTrailingSlash } from "./i18n";
import { createLangUrl } from "./i18n";
import { Spinner } from "react-bootstrap";
const NavBar = lazy(()=> import("./components/layout/NavBar"));
const Footer = lazy(()=> import("./components/layout/Footer"));
const NotFound = lazy(()=> import("./components/NotFound/NotFound"));
const Landing = lazy(()=> import("./components/layout/Landing"));
const About = lazy(()=> import("./components/AboutUs/About"));
const MultiStepForm = lazy(()=> import("./components/Steps/MultiStepForm"));
const Mapping = lazy(()=> import("./components/Mapping"));
// const OnlyForAndroid = lazy(()=> import("./components/Utils/OnlyForAndroid"));
const PrivacyPolicy = lazy(()=> import("./components/PrivacyPolicy/Policy"));

function App() {
    const { t, i18n } = useTranslation();
    const currentUrl = removeTrailingSlash(window.location.pathname);
    const currentLang = i18n.language;

    useEffect(() => {
        const path = window.location.pathname;
        if (path.length > 1 && path.endsWith("/")) {
            window.history.replaceState(null, "", removeTrailingSlash(path));
        }
    }, []);

    useEffect(() => {
        const today = new Date();
        const isFriday13th = today.getDay() === 5 && today.getDate() === 13;
        if (isFriday13th) {
            document.body.classList.add("dark-mode");
        } else {
            document.body.classList.remove("dark-mode");
        }
    }, []);

    return (
        <Fragment>
            <Router>
                <ScrollToTop>
                    <Helmet>
                        <link rel="canonical" href={`${window.location.origin}${currentUrl}`} />
                        {SUPPORTED_LANGUAGES.map((lang) => (
                            <link
                                key={lang}
                                rel="alternate"
                                hrefLang={lang}
                                href={`${window.location.origin}${createLangUrl(lang)}`}
                            />
                        ))}
                        <link
                            rel="alternate"
                            hrefLang="x-default"
                            href={`${window.location.origin}${createLangUrl("en")}`}
                        />
                    </Helmet>
                    <Suspense fallback={<Spinner />}>
                        <NavBar />
                        <Switch>
                            <Route exact path={baseRouteUrl + "/"}>
                                <Helmet>
                                    <title>{t("Home")} - Pokemod</title>
                                    <meta name="title" content={t("Home") + " - Pokemod"} />
                                    <meta name="description" content={t("hero section.description")} />
                                </Helmet>
                                <Landing />
                            </Route>
                            <Route path={baseRouteUrl + "/about-us"}>
                                <Helmet>
                                    <title>{t("About Us")} - Pokemod</title>
                                    <meta name="title" content={t("About Us") + " - Pokemod"} />
                                    <meta name="description" content={t("About Section.About Pokemod.description")} />
                                </Helmet>
                                <About />
                            </Route>
                            <Route path={baseRouteUrl + "/payment"}>
                                <Helmet>
                                    <title>{t("Alternative Payment")} - Pokemod</title>
                                    <meta name="title" content={t("Alternative Payment") + " - Pokemod"} />
                                    <meta name="description" content={t("common.Alternative Payment Gateways")} />
                                </Helmet>
                                <MultiStepForm />
                            </Route>
                            <Route path={baseRouteUrl + "/mapping"}>
                                <Helmet>
                                    <title>{t("common.mapWithPokemod")} - Pokemod</title>
                                    <meta name="title" content={t("common.mapWithPokemod") + " - Pokemod"} />
                                    <meta name="description" content={t("modal.Atlas.text")} />
                                </Helmet>
                                <Mapping />
                            </Route>
                            <Route path={baseRouteUrl + "/privacy-policy"}>
                                <PrivacyPolicy />
                            </Route>

                            <Route path="/pkmd" component={() => {
                                window.location.replace(HalApk);
                                return <BackToHome />;
                            }}/>
                            <Route path="/pogo64" component={() => {
                                window.location.replace(Pogo64Apk);
                                return <BackToHome />;
                            }}/>
                            <Route path="/pogo32" component={() => {
                                window.location.replace(Pogo32Apk);
                                return <BackToHome />;
                            }}/>
                            <Route component={NotFound} status={404} />
                        </Switch>
                        <Footer />
                    </Suspense>
                </ScrollToTop>
            </Router>
        </Fragment>
    );
}

export default App;
