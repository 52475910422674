import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const DETECTION_OPTIONS = {
    order: ["path", "localStorage", "navigator"],
    lookupFromPathIndex: 0,
    checkWhitelist: true,
};

export const SUPPORTED_LANGUAGES = ["en", "zh", "es", "el", "de", "pt-BR", "pt-PT", "pi", "ru"];

export const init_i18n = async () => {
    await i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            detection: DETECTION_OPTIONS,
            supportedLngs: SUPPORTED_LANGUAGES,
            fallbackLng: "en",
            debug: process.env.NODE_ENV === "development",
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: true,
            },
            appendNamespaceToCIMode: true,
            load: "currentOnly",
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
        });

    return i18n;
};

const genRouteLocaleSlug = (languages, lowerCase = false) => {
    return "/:locale(" + languages
        .map(lng => lowerCase ? lng.toLowerCase() : lng)
        .join("|") + ")?";
};

export const baseRouteUrl = genRouteLocaleSlug(SUPPORTED_LANGUAGES);

export const caseInsensitiveCheck = (array, value) => {
    return array.findIndex(element => element.toLowerCase() === value.toLowerCase());
};

export const urlLangParser = (currentPathname) => {
    const possible_lang = currentPathname.split("/")[1];
    if (!possible_lang) return currentPathname;

    if (SUPPORTED_LANGUAGES.includes(possible_lang) ||
        caseInsensitiveCheck(SUPPORTED_LANGUAGES, possible_lang) !== -1) {
        return currentPathname.replace("/" + possible_lang, "") || "/";
    }
    return currentPathname;
};

export const removeTrailingSlash = (url) => {
    return url.endsWith("/") && url !== "/" ? url.slice(0, -1) : url;
};

export const createLangUrl = (lng) => {
    const base = urlLangParser(window.location.pathname);
    if (base === "/") {
        return lng === "en" ? "/" : `/${lng}`;
    }
    return lng === "en" ? base : `/${lng}${base}`;
};
